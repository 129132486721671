import {BrowserRouter,Route,Switch} from 'react-router-dom';
import './App.css';
import Home from './Home';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' component={Home}></Route>
        {/* <Route exact path='/' render={()=><RenderPage pageURL='home'/>}/>
        <Route exact path='/home' render={()=><RenderPage pageURL='home'/>}/>
        <Route exact path='/about' render={()=><RenderPage pageURL='about'/>}/>
        <Route exact path='/projects' render={()=><RenderPage pageURL='projects'/>}/>
        <Route exact path='/skills' render={()=><RenderPage pageURL='skills'/>}/>
        <Route exact path='/contact' render={()=><RenderPage pageURL='contact'/>}/> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
