import { Container,Stack,Typography,Card,CardMedia,CardContent } from '@mui/material'
import DiaryImg from './img/diary.jpg';
import ConquerImg from './img/conquer.png';
import CrmImg from './img/crm.jpg';
import InvoiceImg from './img/invoice.png';
import EcomImg from './img/ecom.jpg';


export default function Projects(){

    const textFont='fontFamily:"poppins"';
    const projects=[
        {
            name:'Diary manager',
            description:'Manage appointments, events, meetings with ease. Write virtual diary every day and with day planner view all your events at one place with calendar view',
            image:DiaryImg,
            demo:'https://siva-diary-manager.netlify.app',
            frontend:'https://github.com/sivaaks/diary-manager-frontend',
            backend:'https://github.com/sivaaks/diary-manager-backend',
        },
        {
            name:'Conquer',
            description:'A fun game with visual and sound effects , fight with enemies with your missile with score and missiles left calulations',
            image:ConquerImg,
            demo:'https://siva-conquer.netlify.app',
            frontend:'https://github.com/sivaaks/conquer',
            backend:'https://github.com/sivaaks/conquer',
        },
        {
            name:'Invoice application',
            description:'Invoice application to generate invoice to your customers within few clicks with product , customer master details and PDF invoice generation',
            image:InvoiceImg,
            demo:'https://siva-invoice-app.netlify.app/',
            frontend:'https://github.com/sivaaks/invoice-app',
            backend:'https://github.com/sivaaks/invoice-app-node',
        },
        {
            name:'CRM application',
            description:'CRM application to manage customer leads, service requests',
            image:CrmImg,
            demo:'https://siva-react-crm.netlify.app/',
            frontend:'https://github.com/sivaaks/crm-app',
            backend:'https://github.com/sivaaks/crm-app-node',
        },
        {
            name:'Ecommerce app',
            description:'Ecommerce app integrated with payment gateway (razorpay) and design cloned close to flipkart',
            image:EcomImg,
            demo:'https://siva-ecom-app.netlify.app/',
            frontend:'https://github.com/sivaaks/ecom-app',
            backend:'https://github.com/sivaaks/ecom-app-backend',
        },
        
     
    ]

    const handleRedirect=link=>window.open(link,'_blank');

    return (
        <Container maxWidth="lg">
            
            <Stack minHeight="1200px" justifyContent="center">
            <Typography textAlign="center" variant="h2" sx={{fontFamily:"Poppins",fontWeight:'bold',color:'#1d0847',padding:'20px'}}>Projects</Typography>
            <Typography variant="h5" textAlign="center" fontFamily="poppins" sx={{fontSize:{xs:'18px',md:'1rem'}}}>
               <span><b>Credentials:</b></span>User:demo@example.com | password:demo123 <br/> Admin:admin@example.com | password:admin123
            </Typography>
            <Stack direction="row" flexWrap={'wrap'}>
                {projects.map((project)=>{
                return <Card key={project.name} sx={{ mt:3,mr:2,maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="diary"
                        height="160"
                        image={project.image}
                    />
                    <CardContent sx={{height:"120px"}}>
                        <Typography sx={{textFont}} gutterBottom variant="h5" component="div">
                            {project.name}
                        </Typography>
                        <Typography sx={{textFont}} variant="body2" color="text.secondary">
                            {project.description}
                        </Typography>
                    </CardContent>
                    {/* <CardActions> */}
                    <Stack height="120px">
                        <Stack>
                            {/* <Button variant="contained" size="large" onClick={()=>{handleRedirect(project.demo)}} sx={{margin:'0px 10px 10px 10px'}}>Live</Button> */}
                            <button className="live-btn" onClick={()=>{handleRedirect(project.demo)}}>Live</button>
                        </Stack>
                        <Stack justifyContent="center" direction="row">
                            <button className="live-btn" onClick={()=>{handleRedirect(project.frontend)}}>Frontend</button>
                            <button className="live-btn" onClick={()=>{handleRedirect(project.backend)}}>Backend</button>
                            {/* <Button variant="contained" size="large" onClick={()=>{handleRedirect(project.frontend)}}>Frontend</Button>
                            <Button variant="contained" size="large" onClick={()=>{handleRedirect(project.backend)}}>Backend</Button> */}
                        </Stack>
                        {/* {project.urls.map((url,index)=>{
                            const urlNow=Object.keys(url)[0];
                            return (
                                <Stack>
                                <Button variant="contained" key={index} size="large" onClick={()=>{handleRedirect(url[urlNow])}}>{urlNow}</Button>
                            </Stack>
                            )
                        })} */}
                    </Stack>
                   
                    {/* </CardActions> */}
                </Card>
                })}
                </Stack>
                </Stack>
        </Container>
    )
}