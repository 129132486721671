import { Container,Stack,Typography,Grid } from '@mui/material';
import { useState } from 'react';
import { NavHashLink  } from 'react-router-hash-link';
import Logo from './Logo.png';


export default function MenuPage(){
    
    const pages=['home','about','skills','projects','contact'];
    const [menu,setMenu]=useState('none');

    const handleOpen=()=>{
        if(menu==='none') setMenu('flex')
        else setMenu('none');
    }
    const handleClose=()=>setMenu('none');

    const capitalize=(string)=>`${string[0].toUpperCase()}${string.slice(1)}`

return(
    <>
        <Grid sx={{fontFamily:'poppins',height:'80px',backgroundColor:'#ff4000',display:{md:'flex',sm:'none',xs:'none'},
                alignItems:'center',position:'sticky',top:0,bottom:20}}>
        <Container sx={{display:{xs:'none',sm:'none',md:'block'}}}>
            <Stack direction="columns">
                <Stack>
                    <img src={Logo} alt="logo" width="300px"></img>
                </Stack>
                <Stack direction="columns" alignItems="center" sx={{marginLeft:'auto',display:{sm:'none',xs:'none',md:'flex'}}}>
    
                    {pages.map((page,index)=>{
                       return ( 
                       <Typography key={index} sx={{color:'white'}}>
                            <NavHashLink
                                smooth to={`/#${page}`}
                                activeClassName="selected"
                                style={{fontFamily:"poppins",color:'white',fontSize:'24px',padding:'10px',textDecoration:'none'}}
                                activeStyle={{backgroundColor:'white',color:'#ff4000',borderRadius:'10px' }}>
                                {capitalize(page)}
                            </NavHashLink>
                        </Typography>
                       )
                    })}

                </Stack>
            </Stack>
        </Container>
        </Grid>
        {/* Mobile responsive */}
        <Grid sx={{fontFamily:'poppins',height:'auto',backgroundColor:'#ff4000',display:{md:'none',sm:'flex',xs:'flex',flexDirection:'column'},
            justifyContent:'center',position:'sticky',top:0,maxWidth:'100%'}}>
            <Container sx={{display:{xs:'block',sm:'block',md:'none',padding:'10px'}}}>
            <Stack direction="row" alignItems="center">
                <i onClick={handleOpen} style={{color:'#fff',fontSize:'32px'}} className="fa fa-bars"></i>
            <Stack direction="row" marginLeft="auto" marginRight="auto" justifyContent="center">
                <img src={Logo} alt="logo" width="240px"></img>
            </Stack>
            </Stack>
            <Stack sx={{display:menu}}>
                {pages.map((page,index)=>{
                return ( 
                <Typography onClick={handleClose} key={index} sx={{color:'white',padding:'10px'}}>
                        <NavHashLink
                            smooth to={`/#${page}`}
                            activeClassName="selected"
                            style={{fontFamily:"poppins",color:'white',fontSize:'24px',padding:'10px',textDecoration:'none'}}
                            activeStyle={{backgroundColor:'white',color:'#ff4000',borderRadius:'10px' }}>
                            {capitalize(page)}
                        </NavHashLink>
                    </Typography>
                )
                })}
            </Stack>
            </Container>
        </Grid>

</>
)
}
