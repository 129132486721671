    import { Container,Stack,Box,Typography } from "@mui/material";
    import Programmer from './Programmer.png';
    import { NavHashLink  } from 'react-router-hash-link';

    export default function Intro(){

    return(
        <Container maxWidth="lg">
            <Stack sx={{
                display:{xs:'flex',md:'flex',sm:'flex'},flexDirection:{xs:'column-reverse',md:'row'},height:'auto',maxWidth:'100%',
            padding:{xs:'50px 0px 50px 0px',md:'100px 0px 100px 0px'}}} direction="row" justifyItems="center" alignItems="center">
                <Stack spacing={1} sx={{display:'block',alignItems:'center'}}>
                <Typography variant="h6" fontFamily="poppins" sx={{fontWeight:'600'}}>
                        Hello there, I'm
                </Typography>
                <Typography fontWeight="bold" fontFamily="poppins"  variant="h3" sx={{color:'#ff4000',fontSize:{xs:'28px',sm:'28px',md:'3rem'}}}>
                    Siva chidambaram
                </Typography>
                <Typography variant="h5" fontFamily="poppins" >
                    <span><b>Full stack</b></span> developer
                </Typography>
                <Typography variant="h5" fontFamily="poppins" fontWeight="400">
                Sincere and goal oriented, love to involve in projects that I work, all in
                </Typography>
                <Stack direction="row" spacing={2}>
                    <i onClick={()=>{window.open('https://github.com/sivaaks','_blank')}} style={{fontSize:'30px',cursor:'pointer'}} className="fa fa-github"></i>
                    <i onClick={()=>{window.open('https://www.linkedin.com/in/siva-chidambaram-91a317192/','_blank')}} style={{fontSize:'30px',cursor:'pointer'}} className="fa fa-linkedin"></i>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <NavHashLink
                        smooth to={`/#projects`}
                        activeClassName="selected"
                        style={{textDecoration:'none'}}>
                        {/* <Button sx={{fontFamily:"poppins",backgroundColor:'#ff4000'}} variant="contained" size="large">Projects</Button> */}
                        <button className="orange-btn">Projects</button>
                    </NavHashLink>
                    <NavHashLink
                        smooth to={`/#contact`}
                        activeClassName="selected"
                        style={{textDecoration:'none'}}>
                        {/* <Button sx={{fontFamily:"poppins",backgroundColor:'#ff4000'}} variant="contained" size="large">Connect with me</Button> */}
                        <button className="orange-btn">Contact me</button>
                    </NavHashLink>
                </Stack>
                </Stack>
                <Box sx={{width:'50%', display:{md:'flex',xs:'none',sm:'none'},justifyContent:'flex-end'}}>
                    <img src={Programmer} alt="programmer" height="400px"></img>
                </Box>
                <Box sx={{width:'50%', display:{md:'none',sm:'flex',xs:'flex'},justifyContent:'center'}}>
                    <img src={Programmer} alt="programmer" height="200px"></img>
                </Box>
            </Stack>
        </Container>
    )
    }