import {Container,Typography,Stack,Box} from '@mui/material';

export default function Skills(){

    const imgWidth="80px";

    const skillSet=[
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg',
            text:'Javascript',
            percent:90
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg',
            text:'React.js',
            percent:90
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg',
            text:'node.js',
            percent:85
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg',
            text:'MySQL',
            percent:95
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg',
            text:'mongoDB',
            percent:80
        },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg',
        //     text:'express'
        // },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg',
            text:'Bootstrap',
            percent:80
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg',
            text:'HTML5',
            percent:90
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg',
            text:'CSS3',
            percent:90
        },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg',
            text:'Material UI',
            percent:80
        },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/photoshop/photoshop-plain.svg',
        //     text:'Photoshop'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dot-net/dot-net-plain.svg',
        //     text:'.NET'
        // },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg',
            text:'git',
            percent:85
        },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg',
        //     text:'Github'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg',
        //     text:'Heroku'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg',
        //     text:'VS code'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/yarn/yarn-original.svg',
        //     text:'yarn'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/woocommerce/woocommerce-original.svg',
        //     text:'Woocommerce'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-plain.svg',
        //     text:'Wordpress'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-plain.svg',
        //     text:'Visual studio'
        // },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg',
            text:'npm',
            percent:80
        },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg',
        //     text:'Linux'
        // },
        // {
        //     icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/filezilla/filezilla-plain.svg',
        //     text:'File zilla'
        // },
        {
            icon:'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg',
            text:'AWS',
            percent:80
        }
    ]

    return (

        <Container maxWidth="lg">
            <Stack minHeight="700px" alignItems="center" justifyContent="center">
            <Typography variant="h2" sx={{fontFamily:"Poppins",fontWeight:'bold',paddingBottom:'20px'}}>Skills</Typography>
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center">
                {skillSet.map((skill,index)=>{
                    return (
                        <Box sx={{backgroundColor:'#fff',width:{xs:'100px',md:'160px'},padding:'5px',margin:'10px',boxShadow:2,borderRadius:3}}>
                        <Stack direction="row" alignItems="center" sx={{flexDirection:{xs:'column',md:'row'}}}>
                        <img alt="skills" key={index} height="50px" width={imgWidth} src={skill.icon} />
                        <Typography fontFamily="Poppins" textAlign="center">{skill.text}</Typography>
                        </Stack>
                        <Stack width="100%">
                            {/* <LinearProgress variant="determinate" value={skill.percent} sx={{height:10,borderRadius:5}}/> */}
                            <progress value={skill.percent} max="100"/>
                            <Typography fontFamily="Poppins" textAlign="center">{skill.percent}%</Typography>
                        </Stack>
                        </Box>
                    )
                })}
            </Stack>
            </Stack>
        </Container>
    )

}