import { Container,Grid } from "@mui/material";
import Intro from './Intro';
import Projects from './Projects';
import About from './About';
import Contact from './Contact';
import Skills from './Skills';
import MenuPage from './MenuPage';
import Footer from './Footer';

export default function Home(){

    return(
        <>
        <MenuPage/>
        {/* <Grid id="home" sx={{backgroundColor:'#fff'}}> */}
        <Grid id="home">
            <Container>
                <Intro/>
            </Container>
        </Grid>
        {/* <Grid id="about" sx={{backgroundColor:'#ff4000'}}> */}
        <Grid id="about">
            <Container>
                <About/>
            </Container>
        </Grid>
        {/* <Grid id="skills" sx={{backgroundColor:'#1d0847'}}> */}
        <Grid id="skills">
            <Container>
                <Skills/>
            </Container>
        </Grid>
        {/* <Grid id="projects" sx={{backgroundColor:'#ccd7e1'}}> */}
        <Grid id="projects">
            <Container>
                <Projects/>
            </Container>
        </Grid>
        {/* <Grid id="contact" sx={{backgroundColor:'pink'}}> */}
        <Grid id="contact">
            <Container>
                <Contact/>
            </Container>
        </Grid>
        <Footer/>
        </>
    );
}