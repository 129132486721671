import { Container,Stack,Box,Typography } from "@mui/material";
import AboutImg from './img/about.jpg'

export default function About(){

    return (
        <Container>
        <Stack sx={{
            display:'flex',height:'auto',flexDirection:{xs:'column-reverse',md:'row'},
            padding:{xs:'0px 0px 0px 0px',md:'100px 0px 100px 0px'}
            }} direction="row" justifyItems="center" alignItems="center">
             <Box sx={{width:'50%', display:{xs:'none',sm:'none',md:'flex',lg:'flex'},justifyContent:'flex-start'}}>
                <img src={AboutImg} alt="about" height="400px"></img>
            </Box>
            <Box sx={{width:'100%', display:{xs:'flex',sm:'flex',md:'none',lg:'none'},justifyContent:'flex-start'}}>
                <img src={AboutImg} alt="about" height="300px"></img>
            </Box>
            <Stack spacing={1} sx={{width:{md:'50%',xs:'100%'}, display:'block',alignItems:'center'}}>
            <Typography fontWeight="bold" fontFamily="poppins"  variant="h3">
                    About
            </Typography>
            <Typography variant="h5" fontFamily="poppins" sx={{fontSize:{xs:'20px',md:'1.5rem'}}}>
                I'm a <span><b>Full stack</b></span> developer
            </Typography>
            <Typography variant="h6" fontFamily="poppins" fontWeight="400" sx={{fontSize:{xs:'18px',md:'1.25rem'}}}>
                An enthusiastic, sincere and excited programmer who likes to involve in the projects all in.I really love to learn new technologies and implement them.I'm Goal oriented and will focus on completing the work given to me on time. Don't beleive me ? Hire me, so that you could see this in action
            </Typography>
            <Stack direction="row">
                {/* <IconButton size="large"><GitHub/></IconButton>
                <IconButton size="large"><LinkedIn/></IconButton> */}
            </Stack>
            <Stack direction="row" spacing={1}>
                {/* <Button sx={{fontFamily:"poppins",color:'#ff4000',backgroundColor:'#fff'}} variant="contained" size="large" onClick={()=>{window.open('https://drive.google.com/file/d/1rFQtzoMfLxi5eE2yHF-ebzaN1AeI039z/view?usp=sharing','_blank')}} >Resume</Button> */}
                <button className="orange-btn" onClick={()=>{window.open('https://drive.google.com/file/d/1rFQtzoMfLxi5eE2yHF-ebzaN1AeI039z/view?usp=sharing','_blank')}}>Resume</button>
            </Stack>
            </Stack>
        </Stack>
    </Container>
    )
}