import {Grid, Typography } from '@mui/material';


export default function Footer(){

    return (
        <>
        <Grid sx={{backgroundColor:'#ff4000',padding:'20px',display:'flex',justifyContent:'center',width:'100%'}}>
            <Typography textAlign="center" sx={{color:'white'}} variant="p">&copy; Copyright 2022 | Siva chidambaram. All rights reserved</Typography>
        </Grid>
        </>
    )

}