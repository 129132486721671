import { Container,Stack,Typography } from '@mui/material';
import { Email,LocalPhone,GitHub,LinkedIn } from '@mui/icons-material';

export default function Contact(){

    const contacts=[
        {
            icon:<Email/>,
            text:'mail@sivachidambaram.com',
            link:'mailto:mail@sivachidambaram.com',
            faIcon:'fa fa-envelope'
        },
        {
            icon:<LocalPhone/>,
            text:'+91 8667084935',
            link:'tel:+918667084935',
            faIcon:'fa fa-phone'
        },
        // {
        //     icon:<WhatsApp/>,
        //     text:'+91 8870265867',
        //     link:'https://api.whatsapp.com/send?phone=918870265867'
        // },
         {
            icon:<GitHub/>,
            text:'/sivaaks',
            link:'https://github.com/sivaaks',
            faIcon:'fa fa-github'
        },
        {
            icon:<LinkedIn/>,
            text:'/siva-chidambaram-91a317192',
            link:'https://www.linkedin.com/in/siva-chidambaram-91a317192/',
            faIcon:'fa fa-linkedin'
        }
    ]

    return (
            <Container maxWidth="xs">
                <Stack minHeight="500px" justifyContent="center">
                <Typography textAlign="center" variant="h2" sx={{fontFamily:"Poppins",fontWeight:'bold',padding:'20px'}}>Contact</Typography>
                <Stack spacing={2} direction="column" sx={{mt:2}} alignItems="center">
                    {contacts.map((contact,index)=>{
                        return (
                            <>
                            {/* <Button key={index} fullWidth onClick={()=>{window.open(contact.link)}} sx={{fontSize:'18px',backgroundColor:'#ff4000'}} size="medium" variant="contained" startIcon={contact.icon}>{contact.text}</Button>  */}
                            <button onClick={()=>{window.open(contact.link)}} style={{width:"100%"}} className="orange-btn"><i style={{marginRight:'10px'}} className={contact.faIcon}></i>{contact.text}</button>
                            </>
                        )
                    })}
                </Stack>
                </Stack>
            </Container>
    )

}
